import React , {useState} from "react";
import { Card  , Form , FormGroup , Input , Button} from "reactstrap";
import { useForm  , Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';


export const Services = (props) => {

  const validationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    service: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email address').required("This feild is required"),
  });
  const [loading , setLoading] = useState(false)

  const { register, handleSubmit, formState: { errors }  , control, setValue , reset} = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    try {
      // Convert form data to URL-encoded format
      setLoading(true)
      const response = await fetch('PHP/server.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(values).toString(),
      });
  
      // Parse the JSON response
      const data = await response.json();
  
      // Check if the response status is OK
      if (response.ok) {
        setLoading(false)
        toast.success("Your form has been successfully submitted.");
        // Clear form fields
        setValue('email', '');
        setValue('name', '');
        setValue('phone', '');
        setValue('service', '');
      } else {
        setLoading(false)
        // Handle non-OK responses (e.g., validation errors)
        toast.error(data.message || "There was an error submitting the form.");
      }
    } catch (error) {
      setLoading(false)

      // Handle fetch errors
      toast.error("There was an error submitting the form.");
      console.error('Fetch error:', error);
    }
  };
  
  return (
    <div id="services">
      <div className="container m-0 p-0 w-auto">
        <div className="section-title">
          <div className="services-offer" >
            <h4 className="mentor">What We Offer</h4>
            <h1 className="heading-title-service">You can avail the Services Below</h1>
            {/* <div className="br-15 mt-2 col-md-8 col-sm-12 thumbnail">
            {props.data
                  ? props.data.map((d, i) => (
                      <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 p-0">
                        <div className="col-md-11 service-card">
                        
                            <h4 className="container-heading">{d.name}</h4>
                            <small  className="paragraph">{d.text}</small>
                        </div>
                      </div>
                    ))
                  : "loading"}
            </div>
            <div  className="col-md-4 br-15" style={{paddingTop:"11rem"}}>
              
            </div> */}
              <div className="row">

            <div className="col-md-8 br-15  thumbnail">
                        {props.data ? props.data.map((d, i) => (
                                              <div key={`${d.name}-${i}`} className="p-0 col-md-6">
                                                <div className="service-card">
                                                
                                                    <h4 className="container-heading">{d.name}</h4>
                                                    <small  className="paragraph">{d.text}</small>
                                                </div>
                                              </div>
                                            ))
                                          : "loading"}
            </div>
            <div className="col-md-4">
            <Card className="form-text">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                      <h4 className="heading-title-discount">Grab Your Discount!</h4>
                      <small  className="subheading-discount">Avail the 1st order discounts and go easy on your pocket.</small>
                      <div className="mt-2">
                      <FormGroup className="mb-0">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="name"
                            className={`custom-form`}
                            placeholder="Name*"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.name && <small style={{color:"red" , display:"flex"}}>{errors.name.message}</small>}
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="email"
                            className={`custom-form`}
                            placeholder="Email*"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.email && <small style={{color:"red" , display:"flex"}}>{errors.email.message}</small>}

                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="number"
                            id="phone"
                            className={`custom-form`}
                            placeholder="Phone"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.phone && <small style={{color:"red" , display:"flex"}}>{errors.phone.message}</small>}
                      <Controller
                    name="service"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="select"  // Use "select" for dropdowns in reactstrap
                        id="service"
                        className="custom-form"
                        placeholder="Select Service*"
                        {...field}
                      >
                        <option value="">Select Service</option> 
                        <option value="Essay">Essay</option>
                        <option value="Coursework">Coursework</option>
                        <option value="Assignment">Assignment</option>
                        <option value="Dissertation">Dissertation</option>
                        <option value="Thesis">Thesis</option>
                        <option value="Research Proposal">Research Proposal</option>
                        <option value="Literature Review">Literature Review</option>
                        <option value="Case Study">Case Study</option>
                        <option value="Reports">Reports</option>
                        <option value="Editing and Proofreading">Editing and Proofreading</option>
                        <option value="PowerPoint Slides">PowerPoint Slides</option>
                        <option value="Others">Others</option>
                      </Input>
                    )}
                  />
                  {errors.service && <small style={{color:"red" , display:"flex"}}>{errors.service.message}</small>}

                      </FormGroup>
                    </div>
                      <FormGroup className="mb-0">
                      <Button className="text-btn-send" style={{display:"flex" ,alignItems:"center" , justifyContent:"center"}}
                      type="submit" disabled={loading}> <div className={`${loading ? 'spinner' : ''}`}></div> Send</Button>
                      </FormGroup>
                  </Form>
              </Card>
            </div>
</div>
          </div>
        </div>
      </div>
    </div>
  );
};
