import React  , {useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";
import {MessageSquare , Edit , Users} from "react-feather";
import {Card , Button , Form , FormGroup , Input , FormFeedback} from 'reactstrap'
import { useLocation } from "react-router-dom";
import { useForm  , Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';

export const Header = (props) => {
const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  phone: Yup.string().required('This field is required'),
  message: Yup.string().required('This field is required'),
  email: Yup.string().email('Invalid email address').required("This feild is required"),
});
  const [loading , setLoading] = useState(false)
  const [loc, setLoc] = useState()
  const location = useLocation();
  useEffect(() => {
    const result =  location.pathname.split('/')[1].split('-')[0];
    setLoc(result)
  }, [location]);

  const { register, handleSubmit, formState: { errors }  , control, setValue , reset} = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      // Convert form data to URL-encoded format
      const response = await fetch('PHP/server.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(values).toString(),
      });
  
      // Parse the JSON response
      const data = await response.json();
  
      // Check if the response status is OK
      if (response.ok) {
        setLoading(false)
        toast.success("Your form has been successfully submitted.");
        // Clear form fields
        setValue('email', '');
        setValue('name', '');
        setValue('phone', '');
        setValue('message', '');
      } else {
        setLoading(false)
        // Handle non-OK responses (e.g., validation errors)
        toast.error(data.message || "There was an error submitting the form.");
      }
    } catch (error) {
      setLoading(false)
      // Handle fetch errors
      toast.error("There was an error submitting the form.");
      console.error('Fetch error:', error);
    }
  };
  
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">

            <div className="col-md-8  intro-text" style={{paddingTop:"150px"}}>
               <div className="text-btn">  <span className=""> {loc === 'dissertation' ? "Acquire the Next-Level Perfection with our" : loc === 'thesis' ?
              "Get Rid of Hindrances in your Way to Success with our": "Add Excellence to your Assignment with our"}</span></div>
              {/* <h3 className="terniary-heading">{location?.pathname === '/' && "Hire us to Hit Proficiency in your Assignments"}</h3> */}
              {loc === 'dissertation' ? <h2 className="main-heading"> <span>Expert </span> <span className="section-a"> Dissertation </span>Writing and Consulting Service</h2>
              : loc === 'thesis' ? <h2 className="main-heading"> <span className="section-a"> Thesis </span>Writing, Editing and Proofreading Services</h2> :
              <h2 className="main-heading"> <span className="section-a"> Assignment </span>Writing, Editing and Proofreading Services</h2>}

              <div className="col-md-12 p-0">
              {location?.pathname === '/' && 
                <div className="col-md-4 review-container">
                  <img alt='' height={30} src='img/mini.png'/>
                  <div style={{display:"flex" , flexDirection:"column" , alignItems:"start"}}>
                  <h3 className='color-white fw-bold mb-0 reviews' style={{fontSize:"18px"}}>TrueReviews</h3>
                  <ReactStars count={5} size={18}  value={4.5} edit={false} activeColor="#FFB618" isHalf/>
                  <h4  className='color-white text-center reviews'>Reviews 4.5</h4>
                  </div>
                </div>  
              }
              {location?.pathname !== '/' &&
               <h3 className="terniary-heading">{loc === 'dissertation' ? "Our Experts are here to satisfy all your Dissertation Concerns" : loc ==='thesis' ?
               "We can help you have a hurdle-free path to achieve your academic goals" : "Hire us to Hit Proficiency in your Assignments"}</h3>
               }
              </div>
              <div  className="col-md-12 p-0" style={{marginTop:"3rem"}}>
              <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                <Users size={30} color="#fff" style={{marginRight:"1rem"}} />
              <div>    
                  <span className="box-title">Professional Consultants</span><br/>
                  <span className="box-description">Our professional Consultants resolve your concerns</span>
              </div>

              </div>
              
              <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
              <Edit size={30} color="#fff" style={{marginRight:"1rem"}} />
              <div>    
                  <span className="box-title">Experienced Editors</span><br/>
                  <span className="box-description">Our professional Consultants resolve your concerns</span>
              </div>
              </div>
              <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
              <MessageSquare size={30} color="#fff" style={{marginRight:"1rem"}} />
              <div>    
                  <span className="box-title">Top-Notch Expertise</span><br/>
                  <span className="box-description">We have expertise in a vast range of subjects</span>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-4 intro-text">
              <div className="col-md-12" >
           
                <Card className="form-text">
                  <Form 
                   onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="heading-title">Get Offer Now!</h4>
                    <small style={{ fontSize: "16px" }}>
                      Enjoy <b style={{ color: "#c8102e" }}>40%</b> off On Your 1st Order
                    </small>
                      <FormGroup className="mb-0">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="name"
                            className={`custom-form`}
                            placeholder="Name"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.name && <small style={{color:"red" , display:"flex"}}>{errors.name.message}</small>}
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="email"
                            className={`custom-form`}
                            placeholder="Email"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.email && <small style={{color:"red" , display:"flex"}}>{errors.email.message}</small>}

                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="number"
                            id="phone"
                            className={`custom-form`}
                            placeholder="Phone"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.phone && <small style={{color:"red" , display:"flex"}}>{errors.phone.message}</small>}

                      <Controller
                        name="message"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            id="message"
                            className={`custom-form`}
                            placeholder="Message"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.message && <small style={{color:"red" , display:"flex"}}>{errors.message.message}</small>}
                      
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Button className="text-btn-send" style={{display:"flex" ,alignItems:"center" , justifyContent:"center"}}
                      type="submit" disabled={loading}> <div className={`${loading ? 'spinner' : ''}`}></div> Send</Button>
                      </FormGroup>
                  </Form>
               </Card>
              </div>
              </div>
            </div>
          </div>
        </div>
        <a href="https://wa.me/447413032986" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
      <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" />
    </a>
      </div>
    </header>
  );
};
