import React , {useState}from "react";
import { Form , Input , Label , FormGroup , Button , Spinner } from "reactstrap";
import { useForm  , Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast  from 'react-hot-toast';

export const Contact = (props) => {
  const validationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
    subject: Yup.string().required('This field is required'),
    message: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email address').required("This feild is required"),
  });

  const [loading , setLoading] = useState(false)
  const {  handleSubmit, formState: { errors }  , control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values) => {
    try {
      // Convert form data to URL-encoded format
      setLoading(true)
      const response = await fetch('PHP/server.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(values).toString(),
      });
  
      // Parse the JSON response
      const data = await response.json();
  
      // Check if the response status is OK
      if (response.ok) {
        setLoading(false)

        toast.success("Your form has been successfully submitted.");
        // Clear form fields
        setValue('email', '');
        setValue('name', '');
        setValue('phone', '');
        setValue('message', '');
        setValue('subject', '');
      } else {
        // Handle non-OK responses (e.g., validation errors)
        setLoading(false)

        toast.error(data.message || "There was an error submitting the form.");
      }
    } catch (error) {
      setLoading(false)
      // Handle fetch errors
      toast.error("There was an error submitting the form.");
      console.error('Fetch error:', error);
    }
  };
  
  
   return (
    <div id="contact">
      <div className="text-center">
            <div className="primary p-7">
              <h4 className="mb-0"><span className="text-btn-custom">AcademicsMaster</span></h4>
              <h1 className="contact-us">Contact <span  style={{fontWeight:"400"}} >Us</span></h1>
            </div>
            <div className="p-3"><h1 className="color-blue fw-bold">What are you waiting for… contact us now!</h1></div>
            <div>
              <h3 className="send-email">Send Us Email</h3>
              <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12"> 
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="label-text">Your Name</Label>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder="Your Name"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.name && <small style={{color:"red" , display:"flex"}}>{errors.name.message}</small>}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-text">Your Phone</Label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="number"
                            id="phone"
                            className="form-control"
                            placeholder="Your Phone"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.phone && <small style={{color:"red" , display:"flex"}}>{errors.phone.message}</small>}
                  </FormGroup>
                </div>
                <div  className="col-md-6">
                  <FormGroup>
                    <Label className="label-text">Your Email</Label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="email"
                            className="form-control"
                            placeholder="Your Email"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.email && <small style={{color:"red" , display:"flex"}}>{errors.email.message}</small>}
                  </FormGroup>              
                <FormGroup>
                    <Label className="label-text">Your Subject</Label>
                    <Controller
                        name="subject"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="subject"
                            className="form-control"
                            placeholder="Your Subject"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.subject && <small style={{color:"red" , display:"flex"}}>{errors.subject.message}</small>}
                </FormGroup>
                </div>
                <div  className="col-md-12">
                <FormGroup>
                    <Label className="label-text">Message</Label>
                    <Controller
                        name="message"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            id="message"
                            className="form-control"
                            placeholder="Your Message"
                            {...field}  // Spread the field props
                          />
                        )}
                      />
                      {errors.message && <small style={{color:"red" , display:"flex"}}>{errors.message.message}</small>}

                </FormGroup>
                <FormGroup style={{float:"left"}}><Button className="custom-button" style={{display:"flex" ,alignItems:"center"}}
                 type="submit" disabled={loading}> <div className={`${loading ? 'spinner' : ''}`}></div> Send</Button></FormGroup>
                </div>
              </div>
              </Form>
            </div>
      </div> 
    </div>
  );
};
