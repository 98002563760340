import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
          <h2 className="color-blue mt-3 mb-0" >What Makes Us Worth Choosing?</h2>
        <div className="row">
           <div className="col-xs-12 col-md-6 mt-3" >
                   <div className="custom-box">
                   <h3 className="color-red">Quality Assurance with the Best Editing and Proofreading Services</h3>
                  <p>Achieving your trust is our priority so we never compromise on the quality of your assignments. Our assignment helpers are eager to give you the best editing and proofreading services.</p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 mt-3">
                   <div className="custom-box">
                   <h3 className="color-red">Plagiarism Check for the Assurance of 100% Originality in the Assignments</h3>
                  <p>We promise to make non-plagiarized content for your assignments. We have premium checkers that we use to assist you in making your assignments 100% original.</p>
                  </div>
                </div>
        </div>
        <div className="row mb-3">
           <div className="col-xs-12 col-md-6 mt-3">
                   <div className="custom-box">
                   <h3 className="color-red">Effective Editing and Proofreading under Strict Deadlines</h3>
                  <p>No need to miss more deadlines. Our experts can work under strict deadlines. We help you complete your assignments on time with ease and perfection.</p>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 mt-3">
                   <div className="custom-box">
                   <h3 className="color-red">Quality Assurance with the Best Editing and Proofreading Services</h3>
                  <p>We help you to come up with errorless assignments. Still, we offer revision policies for your satisfaction because we aim to give you complete peace of mind.</p>
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
};
