import React  , {useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";
import {MessageSquare , Edit , Users} from "react-feather";
import {Card , Button , Form , FormGroup , Input} from 'reactstrap'
import { useLocation } from "react-router-dom";

export const Policy = (props) => {
  const [loc, setLoc] = useState()
  const location = useLocation();
  useEffect(() => {
    const result =  location.pathname.split('/')[1].split('-')[0];
    setLoc(result)
  }, [location]);
  
  return (
    <header id="header">
      <div className="intro-terms">
        <div className="overlay">
          <div className="container">
            <div className="row">
            <div className="col-md-12  text-center" style={{paddingTop:"120px"}}>
                <h2 className="main-heading">Refund Terms & Conditions</h2> 
                <div  className="col-md-12 mb-3" style={{marginTop:"3rem" , textAlign:"start"}}>
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                    <Users size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Professional Consultants</span><br/>
                    <span className="box-description">Our professional Consultants resolve your concerns</span>
                </div>

                </div>
                
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                <Edit size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Experienced Editors</span><br/>
                    <span className="box-description">Our professional Consultants resolve your concerns</span>
                </div>
                </div>
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                <MessageSquare size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Top-Notch Expertise</span><br/>
                    <span className="box-description">We have expertise in a vast range of subjects</span>
                </div>
                </div>
                </div>

            </div>
            <div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{padding:"1rem"}}>
        <div>    
            <h3 className=" " style={{color:"#012169"}}>A client shall be eligible for refund under the following circumstances:</h3>
            <ul style={{listStyle:"auto" , padding:"1rem 5rem"}}>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case all the requirements are shared by the client initially, which are not met even after 5 revisions.</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case a deadline is missed deviating from the original agreed upon commitment</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>It is mandatory to share a detailed, comprehensive &amp; legit feedback report to be eligible for a refund. This report will be audited by our independent audit team to confirm the eligibility of  refund.</p></li>
            </ul>
       </div>
       <div>    
            <h3 className=" " style={{color:"#012169"}}>A client shall NOT be eligible for refund under the following circumstances:</h3>
            <ul style={{listStyle:"auto" , padding:"1rem 5rem"}}>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>Incomplete/Incorrect requirements shared by the client initially leading to customer dissatisfaction.</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case the client does not use our work.</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case the feedback includes changes in the requirement/initial scope after the work is done. For additional/change in requirement, additional cost will be incurred and we will not be responsible for the outcome in case the additional payment is not made for the redo.</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case the client is non-responsive to queries/concerns which result in a delay.</p></li>
                <li><p className="" style={{color:"#666" , fontSize:"18px"}}>In case the client changes his mind after a few hours of placing the order.</p></li>
          
            </ul>
       </div>
      
    </div>
</header>
  );
};
