import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Footer } from "./components/footer";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import "./App.css";
import { Terms } from "./components/terms";
import { AssignmentServices } from "./components/assignmentService";
import { Policy } from "./components/policy";
const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    <Router>
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
           <Services data={landingPageData.Services} />
           <About data={landingPageData.About} />
             <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
            <Footer />
          </>
        }
      />
      <Route
        path="/assignment-service"
        element={
          <>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <Services data={landingPageData.Services} />
            <About data={landingPageData.About} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
            <Footer />
          </>
        }
      />
      <Route
        path="/dissertation-service"
        element={
          <>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <Services data={landingPageData.Services} />
            <About data={landingPageData.About} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
            <Footer />
          </>
        }
      />
      <Route
        path="/thesis-service"
        element={
          <>
            <Navigation />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <Services data={landingPageData.Services} />
            <About data={landingPageData.About} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
            <Footer />
          </>
        }
      />

      {/* Route for the terms & conditions page */}
      <Route
        path="/terms-conditions"
        element={
          <>
            <Navigation />
            <Terms />
            <Footer />
          </>
        }
      />
      <Route
        path="/refund-policy"
        element={
          <>
            <Navigation />
            <Policy />
            <Footer />
          </>
        }
      />
    </Routes>
  </Router>

);
};

export default App;
