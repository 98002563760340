import React ,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";

export const Navigation = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const header = document.getElementById("menu");
  //     if (window.scrollY > 50) {
  //       header.classList.add("scrolled");
  //     } else {
  //       header.classList.remove("scrolled");
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleHomeClick = () => {
    document.getElementById("header").scrollIntoView({ behavior: "smooth" });
    navigate("/", { replace: true });

  };

  const handleClick = (section) => {
    if (window.location.pathname !== '/') {
      navigate('/'); // Navigate to the homepage
      setTimeout(() => {
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
      }, 100); // Delay to ensure the page has loaded
    } else {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }

  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{width:"auto" 
      // , margin:"0rem 8rem"
      }}>
        {/* <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
            <img alt="logo" height={80}  src="img/newLogo.jpg" />
            <div><h3 style={{color:"#fff"}}>AcademicsMaster</h3></div>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li><ScrollLink to="header" onClick={()=>handleHomeClick()} smooth={true} duration={1000} className="page-scroll">Home </ScrollLink></li>
            <li><ScrollLink to="about" smooth={true} duration={1000} className="page-scroll">About Us</ScrollLink></li>
            <li className="page-scroll dropdown">
              <ScrollLink to="services" smooth={true} duration={1000} className="page-scroll">Services</ScrollLink>
              <ul className="dropdown_content">
              <li><a href="/assignment-service"  className="service_item">Assignment Service</a></li>
              <li><a href="/dissertation-service" className="service_item">Dissertation Service</a></li>
              <li><a href="/thesis-service"  className="service_item">Thesis Service</a></li>
            </ul>
            </li>
            <li><ScrollLink to="contact" smooth={true} duration={1000} className="page-scroll">Contact Us</ScrollLink></li>
          </ul>
        </div> */}
      <div className="navbar-header">
  <button
    type="button"
    className="navbar-toggle collapsed"
    data-toggle="collapse"
    data-target="#bs-example-navbar-collapse-1"
  >
    <span className="sr-only"></span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
    <span className="icon-bar"></span>
  </button>
  {/* <div style={{ display: "flex", alignItems: "center" }}> */}
    <img alt="logo" height={35} src="img/logo.png" />
    {/* <h3 style={{ color: "#fff", margin: "0 0 0 10px" }}>AcademicsMaster</h3> */}
  {/* </div> */}
</div>

<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
  <ul className="nav navbar-nav navbar-right">
    <li>
      <ScrollLink to="header" onClick={() => handleHomeClick()} smooth={true} duration={1000} className="page-scroll">Home</ScrollLink>
    </li>
    <li>
      <ScrollLink to="about" smooth={true} duration={1000} onClick={(e) => handleClick('about')} className="page-scroll">About Us</ScrollLink>
    </li>
    <li className="page-scroll dropdown">
      <ScrollLink to="services" smooth={true} duration={1000} onClick={(e) => handleClick('service')} className="page-scroll">Services</ScrollLink>
      <ul className="dropdown_content">
        <li><a href="/assignment-service" className="service_item">Assignment Service</a></li>
        <li><a href="/dissertation-service" className="service_item">Dissertation Service</a></li>
        <li><a href="/thesis-service" className="service_item">Thesis Service</a></li>
      </ul>
    </li>
    <li>
      <ScrollLink to="contact" smooth={true} duration={1000} onClick={(e) => handleClick('contact')} className="page-scroll">Contact Us</ScrollLink>
    </li>
  </ul>
</div>

      </div>
    </nav>
  );
};
