import React  , {useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";
import {MessageSquare , Edit , Users} from "react-feather";
import {Card , Button , Form , FormGroup , Input} from 'reactstrap'
import { useLocation } from "react-router-dom";

export const Terms = (props) => {
  const [loc, setLoc] = useState()
  const location = useLocation();
  useEffect(() => {
    const result =  location.pathname.split('/')[1].split('-')[0];
    setLoc(result)
  }, [location]);
  
  return (
    <header id="header">
      <div className="intro-terms">
        <div className="overlay">
          <div className="container">
            <div className="row">
            <div className="col-md-12  text-center" style={{paddingTop:"120px"}}>
                <h2 className="main-heading">Terms & Conditions</h2> 
                <div  className="col-md-12 mb-3" style={{marginTop:"3rem" , textAlign:"start"}}>
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                    <Users size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Professional Consultants</span><br/>
                    <span className="box-description">Our professional Consultants resolve your concerns</span>
                </div>

                </div>
                
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                <Edit size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Experienced Editors</span><br/>
                    <span className="box-description">Our professional Consultants resolve your concerns</span>
                </div>
                </div>
                <div className="col-md-4" style={{display:"flex" , alignItems:"center" , padding:"10px 0px"}}>
                <MessageSquare size={30} color="#fff" style={{marginRight:"1rem"}} />
                <div>    
                    <span className="box-title">Top-Notch Expertise</span><br/>
                    <span className="box-description">We have expertise in a vast range of subjects</span>
                </div>
                </div>
                </div>

            </div>
            <div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{padding:"1rem"}}>
        <div>    
            <h3 className=" " style={{color:"#012169"}}>Order Placement</h3>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The customers can book their orders through the companies order form on the website or alternatively contact the customer support through email or call.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The customer fills the order form, which is tasked to them. The order form will contain basic details of the order and the requirements to which the customer wants the writer to abide by.</p>
       </div>
        <div>    
            <h3 className="" style={{color:"#012169"}}>Order Revisions</h3>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The pricing of the product is calculated in accordance with the companies pricing and should be paid in advance.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The company initiates work on the product only and after the payment is made from the customer’s side and is authorized from our end.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The customer may request installments options from the customer support team. The order payment can be made through credible payment merchants like PayPal, Stripe and even bank transfers.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The company is not responsible for any technical or procedural issues that may occur during the payment process.</p>
        </div>
        <div>    
            <h3 className="" style={{color:"#012169"}}>Order Revisions</h3>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The customer will not be billed for any kind of revisions they send in as long as they abide by the descriptions provided before the placement of the order.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The customer has the option to place unlimited revisions as long as these revisions are within the scope.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The revision deadline cannot be lower than the deadline communicated during the order filling process.</p>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>Customers can submit revisions through email with the support team.</p>       
       </div>
       <div>    
            <h3 className="" style={{color:"#012169"}}>Privacy</h3>
            <p className="" style={{color:"#666" , fontSize:"18px"}}>The company believes in safeguarding its customer’s information. Hence, the company does not support account creation on the website in order to entirely avoid collecting information and data from the customer. All the information shared in the order detail form is by the customer’s discretion.</p>
       </div>
    </div>
</header>
  );
};
