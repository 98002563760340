import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Phone, Mail, Facebook, Linkedin } from "react-feather";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

export const Footer = (props) => {

  const navigate = useNavigate();

  const handleClick = (section) => {
    if (window.location.pathname !== '/') {
      navigate('/'); // Navigate to the homepage
      setTimeout(() => {
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
      }, 100); // Delay to ensure the page has loaded
    } else {
      document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }

  }
  
  return (
    <div  id="footer">
      <div className="container p-0 m-0 w-auto">
<div className="col-md-12 primary footer-extra-cls">
{/* <ul className="list-item col-md-4 col-xs-12">
<img alt="logo" height={50} src="img/logo.jpeg" />
 </ul>  */}
             {/* <img alt="logo" height={50} src="img/logo.png" /> */}

<ul className="list-item col-md-4 col-xs-12">
          <li className="mb-3" ><h4 className="fw-bold color-white">Home</h4></li>
          <li><ScrollLink to="about" smooth={true} onClick={(e) => handleClick('about')} duration={1000} className=" cursor-pointer page-scroll color-white fs-18">About Us</ScrollLink></li>
          <li><ScrollLink to="service" smooth={true} duration={1000} onClick={(e) => handleClick('service')} className="cursor-pointer page-scroll color-white fs-18">Service</ScrollLink></li>
          <li><ScrollLink to="contact" smooth={true} duration={1000} onClick={(e) => handleClick('contact')}className="cursor-pointer page-scroll color-white fs-18">Contact Us</ScrollLink></li>

          <li><a href="/terms-conditions"  className="color-white cursor-pointer fs-18">Terms & Conditions</a></li>
          <li><a href="/refund-policy"  className="color-white cursor-pointer fs-18">Refund Policy</a></li>
       </ul>
     
       <ul className="list-item col-md-4 col-xs-12">
          <li className="mb-3" ><h4 className="fw-bold color-white">Services</h4></li>
          <li><a href="/assignment-service"  className="color-white cursor-pointer fs-18">Assignment Service</a></li>
          <li><a href="/dissertation-service"  className="color-white cursor-pointer fs-18">Dissertation Service </a></li>
          <li><a href="/thesis-service"  className="color-white cursor-pointer fs-18">Thesis Service</a></li>
       </ul>

       
        <ul className="list-item col-md-4 xol-xs-12">
          <li className="mb-3"><h4 className="fw-bold color-white fs-18">24/7 CUSTOMER SUPPORT</h4></li>
          <li className="list-style" style={{display:"flex" , alignItems:"center"}}>
            <Phone size={20} fill="#c8102e" color=""  style={{marginRight:"1rem"}} />
            <a  className="color-white cursor-pointer fs-18" target="_blank" href="tel:+44 7413 032986">+44 7413 032986</a>
          </li>
          <li className="list-style" style={{display:"flex" , alignItems:"center"}}>
            <Mail size={20} fill="#c8102e"  style={{marginRight:"1rem"}} />
            <a className="color-white cursor-pointer fs-18" href="mailto:info@academicsmaster.co.uk">info@academicsmaster.co.uk </a>
          </li>
          <div className="icon-container" style={{marginRight:"1rem" , marginBottom:"2rem"}}>
            <a href="https://www.facebook.com" target="_blank" rel=""><Facebook size={20} fill="#012169" color="" /></a>
          </div>
          <div className="icon-container">
          <a href="https://www.linkedin.com" target="_blank" rel=""><Linkedin size={20} fill="#012169" color="" /></a>
          </div>
        </ul>

        </div>

       </div>
       <div  className="text-center" style={{marginTop:"1rem"}}>  <p className="mb-0"> Copyright © 2024 AcedemicsMaster | All Rights Reserved. </p></div>
</div>
);
};
